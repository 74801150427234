import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import * as Scroll from 'react-scroll';

import { Layout } from '../common/utils';

import { addToCart, removeFromCart } from "../../redux/actions/cart";
import { PageHeader, ProductCard, ProductSlider } from '../common';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const dispatch = useDispatch();

    const search = new URLSearchParams(useLocation().search);
    const products = useSelector(state => state.products);
    const wishlist = useSelector(state => state.wishlist.data);

    const [product, setProduct] = useState({});
    const [productSize, setProductSize] = useState("");
    const [productColor, setProductColor] = useState("");
    const [productQuantity, setProductQuantity] = useState(1);
    const [productTab, setProductTab] = useState(0);

    const isProductInWishlist = (product) => {
        return wishlist[product.id] != undefined;
    }

    useEffect(() => {
        products?.data?.map((value, idx) => {
            if (value.id == search.get("id")) {
                setProduct({
                    ...value,
                    images: JSON.parse(value.images),
                    content: value.content,
                });
            }
            else {
                if (value.title == search.get("titlu")) {
                    setProduct(value);
                }
            }
        })

    }, [products, search.get("id"), search.get("titlu")]);

    return (
        <>
            {
                JSON.stringify(product) != "{}" ?
                    <Helmet>
                        <title>{product?.title}.</title>
                        <meta name="description" content={product?.description} />
                    </Helmet>
                    :
                    <Helmet>
                        <title>Our product.</title>
                        <meta name="description" content="Apasa pentru a vedea detalii despre produsul nostru!" />
                    </Helmet>
            }
            <Layout>
                <PageHeader page={` Products / ${product?.title}`} />
                {
                    JSON.stringify(product) != "{}" ?
                        <div className={isMobile ? "product__container m-auto" : "product__container m-auto p-5"}>
                            <ProductSlider images={product?.images} />

                            <div className="d-flex flex-column w-100 p-3 pt-0 justify-content-start">
                                <div className='title'>
                                    {product.title}
                                </div>

                                <div className='price'>
                                    {
                                        product?.content?.promo > 0 ?
                                            <>
                                                <strike>{product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {product.content?.promo}%</span> {(Math.floor((product.price - product.price * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                            </>
                                            :
                                            <>
                                                {product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                            </>
                                    }
                                </div>

                                {
                                    product.stock == 0 ?
                                        <div className='price fs-6'>
                                            Product is out of stock for the moment
                                        </div>
                                        :
                                        product.stock > 10 ?
                                            <div className='fs-6'>
                                                The product is in stock
                                            </div>
                                            :
                                            <div className='price fs-6'>
                                                The product is in limited stock
                                            </div>
                                }

                                <div className='d-flex flex-column justify-content-start gap-4 my-4'>
                                    <div>
                                        <div className='label'>Colors:</div>
                                        {
                                            product?.content?.colors && product?.content?.colors.length > 0 ?
                                                <div className='value colors'>
                                                    {product?.content?.colors.map((value, idx) => (
                                                        <div key={idx} className={(productColor == value ? ' active' : '')}>
                                                            <div className={`color ${value}`}
                                                                onClick={() => {
                                                                    setProductColor(value);
                                                                }}
                                                            >
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div>
                                        <div className='label'>Sizes:</div>
                                        {
                                            product?.content?.sizes && product?.content?.sizes.length > 0 ?
                                                <>
                                                    <div className='value sizes'>
                                                        {
                                                            product?.content?.sizes.map((value, idx) => (
                                                                <div key={idx} className={'size' + (productSize == value ? ' active' : '')}
                                                                    onClick={() => {
                                                                        setProductSize(value);
                                                                    }}
                                                                >
                                                                    <div className="value">
                                                                        {value}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex flex-row justify-content-between align-items-center gap-2'>
                                    <div className='quantity'>
                                        <div className='button' onClick={() => setProductQuantity(productQuantity == 1 ? 1 : (productQuantity - 1))}>
                                            <span class="material-icons-outlined">
                                                remove
                                            </span>
                                        </div>
                                        {productQuantity}
                                        <div className='button' onClick={() => setProductQuantity(productQuantity + 1)}>
                                            <span class="material-icons-outlined">
                                                add
                                            </span>
                                        </div>
                                    </div>

                                    <div className="button button--buy" onClick={() => {
                                        if (productColor != "") {
                                            if (productSize != "") {
                                                if (product.stock == 0) {
                                                    alert("Produsul nu este in stoc!");
                                                    return;
                                                }

                                                console.log(product.id, JSON.stringify({ color: productColor, size: productSize }), productQuantity);

                                                dispatch(addToCart(product.id, JSON.stringify({ color: productColor, size: productSize }), productQuantity));
                                            }
                                            else {
                                                alert("Alege o marime!");
                                                return;
                                            }
                                        }
                                        else {
                                            alert("Alege o culoare!");
                                            return;
                                        }
                                    }}>
                                        Add to cart
                                    </div>

                                    <div className={isProductInWishlist(product) ? "button--rev-active" : "button--reverse"} onClick={() => {
                                        if (isProductInWishlist(product)) {
                                            dispatch(removeFromWishlist(product.id));
                                        }
                                        else {
                                            dispatch(addToWishlist(product.id));
                                        }
                                    }}>
                                        <img className="my-auto" src={require("../../assets/wishlist.png").default} alt="Wishlist." />
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    NOTE: We need 10 to 14 days to make and ship existing designs
                                    <br />
                                    7 to 10 days for production
                                    <br />
                                    2 to 4 days for shipping
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <div className='mx-5 mb-1 card' onClick={() => setProductTab(productTab != 1 ? 1 : 0)}>
                    <div className='bg-black text-white w-100 p-3 d-flex flex-row justify-content-between'>
                        <div>Description</div>

                        <div class="pointer material-icons-outlined">

                            {
                                productTab == 1 ?
                                    "keyboard_arrow_up"
                                    :
                                    "keyboard_arrow_down"
                            }
                        </div>
                    </div>

                    {
                        productTab == 1 &&
                        <div className='p-3'>{product.description.split(/\n/).map(line => <React.Fragment key={line}>{line}<br /></React.Fragment>)}</div>
                    }
                </div>

                <div className='mx-5 mb-5 card' onClick={() => setProductTab(productTab != 2 ? 2 : 0)}>
                    <div className='bg-black text-white w-100 p-3 d-flex flex-row justify-content-between'>
                        <div>Additional</div>

                        <div class="pointer material-icons-outlined">

                            {
                                productTab == 2 ?
                                    "keyboard_arrow_up"
                                    :
                                    "keyboard_arrow_down"
                            }
                        </div>
                    </div>

                    {
                        productTab == 2 &&
                        <div className='p-3'>
                            <table class="table border">
                                <tbody>
                                    <tr>
                                        <th scope="col">COLOR</th>
                                        <th>{product?.content?.colors.map(value => value.replace(/^./, value[0].toUpperCase()) + " ")}</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">SIZES</th>
                                        <td>{product?.content?.sizes.map(value => value.replace(/^./, value[0].toUpperCase())) + " "}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                <div>
                    <h1 className='text-center bg-black text-light w-fit p-4 pt-2 pb-2 m-auto mb-5'>You may also like</h1>
                    <div className="products">
                        {
                            products?.data?.sort((elm1, elm2) => (elm2?.id - elm1?.id)).filter((elm) => elm?.category_id === product?.category_id && elm.id !== product?.id).slice(0, 3).map((value, idx) => (
                                <ProductCard key={idx} product={{ ...value, images: JSON.parse(value.images), content: value.content }} />
                            ))
                        }
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Index;