import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from '../common/utils';
import { PageHeader } from '../common';
import { fetchOrder } from '../../redux/actions/orders';

const Index = () => {
    const dispatch = useDispatch();
    const search = new URLSearchParams(useLocation().search);
    const order = useSelector(state => state.orders.data);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {
        if (search.get("id")) {
            dispatch(fetchOrder({ id: search.get("id") }));
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Track order.</title>
            </Helmet>
            <PageHeader page="Track order" />

            <Layout>
                <div className='d-flex flex-column justify-content-center align-items-center p-5 text-black'>
                    <div className="title text-center">
                        <b>Your order's details.</b>
                        <p className='text-dark'>Check all the details about your ongoing order.</p>
                    </div>
                    <br />
                    <div className={isMobile ? "details" : "details w-50"}>
                        <b>Full Name: </b><br />{order?.firstname} {order?.lastname}<br />
                        <b>Status:</b> <br />
                        {
                            order?.status == "pending" ?
                                "Pending."
                                :
                                order?.status == "processing" ?
                                    "Processing."
                                    :
                                    order?.status == "shipped" ?
                                        "Shipped."
                                        :
                                        order?.status == "delivered" ?
                                            "Delivered."
                                            :
                                            "Cancel."
                        }
                        <br />
                        <b>Date:</b> <br />{new Date(order?.date).toLocaleString('RO', { timeZone: "Europe/Bucharest" }).slice(0, 10)}<br />
                        <b>Phone number:</b> <br />{order?.phone}<br />
                        <b>Email:</b> <br />{order?.email}<br />

                        {
                            order?.awb && (
                                <>
                                    <b>AWB:</b> <br />{order?.awb}<br />
                                </>
                            )
                        }

                        <b>Delivery Method:</b> <br />{order?.delivery_method}<br />
                        <b>Address:</b> <br />{order?.country}, {order?.county}, {order?.city}, {order?.address}<br />
                        <b>Observations:</b> <br />{order?.obs ? order?.obs : "Nu exista!"}<br />

                        <b>Billing:</b>
                        {
                            order?.cart && JSON.parse(order?.cart).map((item, index) => {
                                return (
                                    item &&
                                    <>
                                        <div className="checkout__item" key={index}>
                                            <div className="checkout__item__name">
                                                {item.title} {item.color} {item.size} {item.amount} pcs.
                                            </div>

                                            <div className="checkout__item__price">
                                                {
                                                    item.promo > 0 ?
                                                        <>
                                                            +<strike>{(item.amount * item.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {item.promo}%</span> {(item.amount * (item.price - item.price * item.promo / 100)).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                        </>
                                                        :
                                                        <>
                                                            +{(item.amount * item.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )
                            })
                        }

                        {
                            order?.promo && order?.promo != "{}" &&
                            <>
                                <div className="checkout__item">
                                    <div className="checkout__item__name">
                                        PROMO {JSON.parse(order?.promo)?.name}
                                    </div>
                                    <div className="checkout__item__price">
                                        -{JSON.parse(order?.promo)?.value}%
                                    </div>
                                </div>

                                <br />
                            </>
                        }

                        <div className="checkout__item">
                            <div className="checkout__item__name">
                                Shipping
                            </div>

                            <div className="checkout__item__price">
                                +{order?.delivery_price?.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </div>
                        </div>
                        <br />

                        <div className="checkout__item">
                            <div className="checkout__item__name">
                                Total
                            </div>

                            <div className="checkout__item__price">
                                {order?.price?.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Index;