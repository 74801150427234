import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from "../common/utils";
import { PageHeader } from '../common';

import { register } from '../../redux/actions/account';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const dispatch = useDispatch();
    const { error, inLoading, data } = useSelector(state => state.account);

    const [password, setPassword] = useState("");
    const [cPassword, setConfirmedPassword] = useState("");

    const [userState, setUserState] = useState({
        email: "",
        firstname: "",
        lastname: "",
        phone: ""
    });

    const updateDataField = (field, value) => {
        setUserState({
            ...userState,
            [field]: value
        });
    };

    return (
        localStorage.getItem("visitor") == "true" ?
            <>
                <Helmet>
                    <title>Sign Up.</title>
                </Helmet>
                <PageHeader page="Sign Up" />

                <Layout>
                    <h1 className="text-center fs-4 text-black bold landing__title m-auto">Sign Up now.</h1>
                    <p className="error text-center text-danger mt-4">{error}</p>
                    <div className='input__group w-auto'>
                        <div className='d-flex flex-column flex-wrap justify-content-start gap-5'>
                            <div>
                                <label>First name</label>
                                <input
                                    id="fname" name="fname"
                                    type="text"
                                    placeholder="Your first name"
                                    value={userState.firstname}
                                    onChange={e => updateDataField("firstname", e.target.value)}
                                />
                            </div>

                            <div>
                                <label>Last name</label>
                                <input
                                    id="lname" name="lname"
                                    type="text"
                                    placeholder="Your last name"
                                    value={userState.lastname}
                                    onChange={e => updateDataField("lastname", e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    id="email" name="email"
                                    type="email"
                                    placeholder="Your email"
                                    value={userState.email}
                                    onChange={e => updateDataField("email", e.target.value)}
                                />
                            </div>

                            <div>
                                <label>Phone number</label>
                                <input
                                    id="phone" name="phone"
                                    type="phone"
                                    placeholder="Your phone number"
                                    value={userState.phone}
                                    onChange={e => updateDataField("phone", e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Password</label>
                                <input
                                    id="password" name="password"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <label>Confirm password</label>
                                <input
                                    id="cpassword" name="cpassword"
                                    type="password"
                                    placeholder="Password"
                                    value={cPassword}
                                    onChange={e => setConfirmedPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="button m-auto mt-4" onClick={() => {
                            if (password === cPassword) {
                                dispatch(register({
                                    ...userState,
                                    password: password
                                }));
                            }
                            else {
                                alert("Passwords do not match.")
                            }
                        }
                        }>
                            Register
                        </div>
                    </div>


                    <div className='text-center h6 text-black'>
                        or
                        <br></br>
                        Press <Link to="/login" className="text-danger">here</Link> if you already have an account.
                    </div>
            </Layout >
        </>
        :
<Redirect to="/" />
    );
};

export default Index;