import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../common/utils";
import { Helmet } from 'react-helmet';
import { PageHeader } from '../common';

import { editPage } from "../../redux/actions/page";

const Index = () => {

    return (
        <>
            <Helmet>
                <title>Gallery.</title>
            </Helmet>
            <PageHeader page="Gallery" />
            <Layout>
                <center>
                    <h1 className="text-center fs-1 text-black bold landing__title m-auto w-fit">ARTWORK</h1>

                    <div className="gallery">
                        <img className="image" src={require("../../assets/gallery/image (1).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (3).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (4).png").default} alt="Artwork" />
                    </div>
                    <div className="gallery">
                        <img className="image" src={require("../../assets/gallery/image (5).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (6).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (7).png").default} alt="Artwork" />
                    </div>
                    <div className="gallery">
                        <img className="image" src={require("../../assets/gallery/image (8).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (9).png").default} alt="Artwork" />
                        <img className="image" src={require("../../assets/gallery/image (2).png").default} alt="Artwork" />
                    </div>
                </center>
            </Layout>
        </>
    );
};

export default Index;