import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../common/utils";
import { Helmet } from 'react-helmet';
import { PageHeader } from '../common';

import { editPage } from "../../redux/actions/page";

const Index = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);
    
    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState(getPageParameter("custom_merch_text"));

    return (
        <>
            <Helmet>
                <title>Custom Merch.</title>
            </Helmet>
            <PageHeader page="Custom Merch" />


            <center className="d-flex flex-column m-auto w-fit">
                <h1 className="text-center fs-4 text-black bold landing__title m-auto">Make your own designs with us</h1>
                <div className="landing__categories m-2">
                    <div className="category">
                        <img className="background" src={require("../../assets/hoodies.png").default} alt='index' />
                    </div>
                    <div className="category">
                        <img className="background" src={require("../../assets/crewnecks.png").default} alt='index' />
                    </div>
                    <div className="category">
                        <img className="background" src={require("../../assets/t-shirts.png").default} alt='index' />
                    </div>
                </div>
                
                <div className="m-5 p-5">
                    <p className='w-75 fs-4 text-black'>
                    {
                        editMode ?
                        <textarea className="text-center w-100" value={text} onChange={e => setText(e.target.value)} />
                        :
                        <div className="whitespace-break-spaces" dangerouslySetInnerHTML={{__html: text}}></div>
                    }

                    {
                        account?.type == "master"  &&
                        (
                            editMode ?
                            <div className="d-flex flex-row m-auto">
                                <div className="m-4 user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => {
                                        setEditMode(!editMode);
                                        
                                        dispatch(editPage({
                                            value: text,
                                            name: "custom_merch_text"
                                        }))
                                    }}>done</span>
                                </div>
                                <div className="m-4 user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => {setEditMode(!editMode); setText(text)}}>close</span>
                                </div>
                            </div>
                            :
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                            </div>
                        )
                    }
                    </p>
                </div>
            </center>
        </>
    );
}

export default Index;