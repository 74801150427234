import axios from "axios";

export const PAGE_STARTING = 'PAGE_STARTING';
export const PAGE_SUCCESSFULL = 'PAGE_SUCCESSFULL';
export const PAGE_FAILED = 'PAGE_FAILED';

export function pageStarting(payload) {
    return {
        type: PAGE_STARTING,
        payload: payload
    };
}

export function pageSuccessfull(payload) {
    return {
        type: PAGE_SUCCESSFULL,
        payload: payload
    };
}

export function pageFailed(payload) {
    return {
        type: PAGE_FAILED,
        payload: payload
    };
}

export const fetchPage = (props) => {
    return async (dispatch) => {
        dispatch(pageStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/parameters`)
        .then(res => {
            dispatch(pageSuccessfull(res.data));
        }).catch(err => {
            dispatch(pageFailed(err.response.data.error.message));
        });
    }
};

export const editPage = (props) => {
    return async (dispatch) => {
        axios.put(`${process.env.REACT_APP_API_KEY}/parameters`,
        {
            ...props
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            dispatch(pageStarting());
    
            axios.get(`${process.env.REACT_APP_API_KEY}/parameters`)
            .then(res => {
                dispatch(pageSuccessfull(res.data));
            }).catch(err => {
                dispatch(pageFailed(err.response.data.error.message));
            });
        }).catch(err => {});
    }
};