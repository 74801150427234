import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from '../common/utils';
import { PageHeader } from '../common';
import { fetchOrdersHistory, fetchOrdersMaster, editOrder } from '../../redux/actions/orders';

const Order = ({ accountType, order }) => {
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [status, setStatus] = useState(order?.status);
    const [AWB, setAWB] = useState(order?.awb);

    useEffect(() => {
        setStatus(order?.status);
    }, [order]);

    return (
        <div className={isMobile ? 'card p-4' : 'card p-4 w-25'}>
            <div className="details">
                <b>Name: </b><br />{order?.firstname} {order?.lastname}<br />

                {
                    accountType === "master" ?
                        <div className='input__group m-0 p-0'>
                            <div>
                                <b>Status:</b><br />
                                <select value={status} onChange={e => setStatus(e.target.value)}>
                                    <option value="pending">Pending.</option>
                                    <option value="processing">Processing.</option>
                                    <option value="shipped">Shipped.</option>
                                    <option value="delivered">Delivered.</option>
                                    <option value="canceled">Canceled.</option>
                                </select>
                            </div>
                        </div>
                        :
                        <>
                            <b>Status:</b> <br />
                            {
                                order?.status == "pending" ?
                                    "Pending"
                                    :
                                    order?.status == "processing" ?
                                        "Processing."
                                        :
                                        order?.status == "shipped" ?
                                            "Shipped."
                                            :
                                            order?.status == "delivered" ?
                                                "Delivered."
                                                :
                                                "Canceled."
                            }
                            <br />
                        </>
                }
                <b>Date:</b> <br />{new Date(order?.date).toLocaleString('RO', { timeZone: "Europe/Bucharest" }).slice(0, 10)}<br />

                <b>Phone Number:</b> <br />{order?.phone}<br />
                <b>Email:</b> <br />{order?.email}<br />
                {
                    accountType === "master" ?
                        <div className='input__group m-0 p-0'>
                            <div>
                                <b>AWB:</b><br />
                                <input type="text" value={AWB} onChange={e => setAWB(e.target.value)} />
                            </div>
                        </div>
                        :
                        order?.awb && (
                            <>
                                <b>AWB:</b> <br />{order?.awb}<br />
                            </>
                        )
                }

                <b>Delivery method:</b> <br />{order?.delivery_method}<br />
                <b>Address:</b> <br />{order?.country}, {order?.county}, {order?.city}, {order?.address}<br />
                <b>Observations:</b> <br />{order?.obs ? order?.obs : "Nu exista!"}<br />

                <b>Billing:</b>
                {
                    order?.cart && JSON.parse(order?.cart).map((item, index) => {
                        return (
                            item &&
                            <Link to={`/product?id=${item.id}`}>
                                <div className="checkout__item" key={index}>
                                    <div className="checkout__item__name">
                                        {item.title} {item.color} {item.size} {item.amount} pcs.
                                    </div>

                                    <div className="checkout__item__price">
                                        {
                                            item.promo > 0 ?
                                                <>
                                                    +<strike>{(item.amount * item.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {item.promo}%</span> {(item.amount * (item.price - item.price * item.promo / 100)).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                </>
                                                :
                                                <>
                                                    +{(item.amount * item.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                </>
                                        }
                                    </div>
                                </div>
                                <br />
                            </Link>
                        )
                    })
                }

                {
                    order?.promo != "{}" &&
                    <>
                        <div className="checkout__item">
                            <div className="checkout__item__name">
                                PROMO {JSON.parse(order?.promo)?.name}
                            </div>
                            <div className="checkout__item__price">
                                -{JSON.parse(order?.promo).value}%
                            </div>
                        </div>

                        <br />
                    </>
                }

                <div className="checkout__item">
                    <div className="checkout__item__name">
                        Shipping
                    </div>

                    <div className="checkout__item__price">
                        +{order?.delivery_price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                    </div>
                </div>
                <br />

                <div className="checkout__item">
                    <div className="checkout__item__name">
                        Total
                    </div>

                    <div className="checkout__item__price">
                        {order?.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                    </div>
                </div>
            </div>

            {
                accountType === "master" &&
                <div className="d-flex flex-row m-auto">
                    <div className="button m-4 user-select-none pointer" onClick={() => {
                        editOrder(
                            {
                                ...order,
                                status: status,
                                awb: AWB
                            }
                        )
                    }}>
                        <span className="icon material-icons-outlined">done</span>
                        Apply changes
                    </div>
                </div>
            }
        </div>
    )
}

const Index = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const orders = useSelector(state => state.orders.data);

    useEffect(() => {
        console.log(account)
        if (account?.type === "master") {
            dispatch(fetchOrdersMaster());
        }
        else {
            dispatch(fetchOrdersHistory());
        }
    }, [account]);

    return (
        <>
            <Helmet>
                <title>Your orders.</title>
            </Helmet>
            <PageHeader page="Your orders" />

            <Layout>
                <div className='d-flex flex-row flex-wrap justify-content-center p-5 gap-5'>
                    {
                        orders.length > 0 ?
                            orders?.sort((a, b) => b?.id - a?.id)?.map((order, idx) => (
                                <Order order={order} accountType={account?.type} idx={idx} />
                            ))
                            :
                            <center>
                                Your order history is empty, check our <Link className="text-danger" to="/products">products</Link> to fill it up.
                            </center>
                    }
                </div>
            </Layout>
        </>
    );
};

export default Index;