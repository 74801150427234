import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../common/utils";
import { Helmet } from 'react-helmet';
import { PageHeader } from '../common';

import { editPage } from "../../redux/actions/page";

const Index = () => {

    return (
        <>
            <Helmet>
                <title>Size Guide.</title>
            </Helmet>
            <PageHeader page="Size Guide" />
            <Layout>
                <center>
                    <img src={"https://cdn.shopify.com/s/files/1/0585/5834/9490/files/WhatsApp_Image_2022-07-16_at_5.06.08_PM_480x480.jpg?v=1658218438"} alt="Size Guide" />
                    <div>
                        <h2>T-Shirt</h2>
                        <table class="table border w-75">
                            <tbody>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Width (inch)</th>
                                    <th scope="col">Height (inch)</th>
                                </tr>
                                <tr>
                                    <th scope="col">S</th>
                                    <th scope="col">23</th>
                                    <th scope="col">28</th>
                                </tr>
                                <tr>
                                    <th scope="col">M</th>
                                    <th scope="col">24</th>
                                    <th scope="col">28.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">L</th>
                                    <th scope="col">25</th>
                                    <th scope="col">28.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">XL</th>
                                    <th scope="col">25.5</th>
                                    <th scope="col">30</th>
                                </tr>
                                <tr>
                                    <th scope="col">XXL</th>
                                    <th scope="col">26</th>
                                    <th scope="col">30</th>
                                </tr>
                            </tbody>
                        </table>
                        
                        <h2>Hoodie and Sweatshirt</h2>
                        <table class="table border w-75">
                            <tbody>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Width (inch)</th>
                                    <th scope="col">Height (inch)</th>
                                </tr>
                                <tr>
                                    <th scope="col">S</th>
                                    <th scope="col">24</th>
                                    <th scope="col">26</th>
                                </tr>
                                <tr>
                                    <th scope="col">M</th>
                                    <th scope="col">25</th>
                                    <th scope="col">26.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">L</th>
                                    <th scope="col">25.5</th>
                                    <th scope="col">27</th>
                                </tr>
                                <tr>
                                    <th scope="col">XL</th>
                                    <th scope="col">27</th>
                                    <th scope="col">29</th>
                                </tr>
                                <tr>
                                    <th scope="col">XXL</th>
                                    <th scope="col">27.5</th>
                                    <th scope="col">31</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </center>
            </Layout>
        </>
    );
};

export default Index;