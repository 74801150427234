import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { addToWishlist, removeFromWishlist } from "../../redux/actions/wishlist";
import { Link } from "react-router-dom/cjs/react-router-dom";

const NavWishlist = ({ show, onClose }) => {
    const dispatch = useDispatch();
    const wishlist = useSelector(state => state.wishlist.data);
    const products = useSelector(state => state.products.data);

    return (
        show ?
        <div className="nav__menu">
                    <div className="nav__menu__overlay" id="nav__menu__overlay" onClick={onClose}></div>

                    <div className="nav__menu__content" id="nav__menu__content">
                        <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
                            <h1 className="fs-6 text-uppercase text-black m-0">Your Wishlist</h1>
                            <img src={require("../../assets/close-o.svg").default} alt="Close" className="nav__menu__close" onClick={onClose} />
                        </div>

                        {
                            Object.keys(wishlist)?.length > 0 ?
                            Object.keys(wishlist)?.map((item, key) => {
                                const product = products.find(product => product.id == item);

                                if (product === undefined) {
                                    return;
                                }

                                return (
                                    <Link className="cart__product p-2" key={key} to={`/product?id=${product?.id}`}>
                                        <img
                                            className="cart__product__image"
                                            src={JSON.parse(product.images)[0]}
                                            alt={product.name}
                                            style={{
                                                "opacity": product.stock > 0 ? 1 : 0.5
                                            }} />
                                        <div className="cart__product__info">
                                            <div>{product.title}</div>
                                        </div>

                                        <div className="d-flex flex-column gap-2">
                                            <div className="button" onClick={() => dispatch(removeFromWishlist(item))}>Remove</div>
                                        </div>
                                    </Link>
                                )
                            })
                            :
                            <div className="text-center my-4">
                                There are no products in wishlist.
                            </div>
                        }
                    </div>
                </div>
            :
            <></>
    );
}

export default NavWishlist;