import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from "../common/utils";
import { PageHeader } from '../common';

import { logIn } from '../../redux/actions/account';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;
    
    const dispatch = useDispatch();
    const { error, inLoading, data } = useSelector(state => state.account);

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    return (
        localStorage.getItem("visitor") == "true" ?
        <>
            <Helmet>
                <title>Sign In.</title>
            </Helmet>
            <PageHeader page="Sign In" />

            <Layout>
                <h1 className="text-center fs-4 text-black bold landing__title m-auto">Sign In now.</h1>
                <p className="error text-center text-danger mt-4">{error}</p>
                <div className='input__group w-auto'>
                    <div className='d-flex flex-column flex-wrap justify-content-start gap-5'>
                        <div>
                            <label>Email</label>
                            <input 
                                type="text" 
                                placeholder="..." 
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div>
                            <label>Password</label>
                            <input 
                                type="password" 
                                placeholder="..." 
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    
                    <div className="button m-auto mt-4" onClick={() => dispatch(logIn({email, password}))}>Log in now</div>
                </div>
                <div className='text-center h6 text-black'>
                    or 
                    <br></br>
                    Press <Link to="/register" className="text-danger">here</Link> if you don't have an account.
                </div>
            </Layout>
        </>
        :
        <Redirect to="/" />
    );
};

export default Index;