import axios from 'axios';

export const ADD_ITEM_WISHLIST = 'ADD_ITEM_WISHLIST';
export const REMOVE_ITEM_WISHLIST = 'REMOVE_ITEM_WISHLIST';
export const REMOVE_ITEM_ALL_WISHLIST = 'REMOVE_ITEM_ALL_WISHLIST';

export function addItem(item) {
    return {
        type: ADD_ITEM_WISHLIST,
        item: item
    };
}

export function removeItem(item) {
    return {
        type: REMOVE_ITEM_WISHLIST,
        item: item
    };
}

export function removeAllItems() {
    return {
        type: REMOVE_ITEM_ALL_WISHLIST
    };
}

export const fetchWishlist = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_API_KEY}/wishlists?token=${localStorage.getItem("token")}`)
        .then(res => {
            for (const item of res.data) {
                dispatch(addItem(item.id));
            }
        }).catch(err => {
            console.log(err.response.data.error.message);
        });
    };
}

export const addToWishlist = (payload) => {
    return dispatch => {

        if (localStorage.getItem("visitor") === "true") {
            dispatch(addItem(payload));
        }
        else {
            axios.post(`${process.env.REACT_APP_API_KEY}/wishlists`,
            {
                product_id: payload
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                }
            })
            .then(res => {
                dispatch(addItem(payload));
            }).catch(err => {
                console.log(err.response.data.error.message);
            });
        }

    };
}

export const removeFromWishlist = (payload) => {
    return dispatch => {
        
        if (localStorage.getItem("visitor") === "true") {
            dispatch(removeItem(payload));
        }
        else {
            axios.delete(`${process.env.REACT_APP_API_KEY}/wishlists`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                },
                data: {
                    product_id: payload
                }
            })
            .then(res => {
                dispatch(removeItem(payload));
            }).catch(err => {
                console.log(err.response.data.error.message);
            });
        }
    };
}

export const clearWishlist = () => {
    return dispatch => {
        dispatch(removeAllItems());
    };
}