import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilterCategories } from "../../redux/actions/filters";

import LogoProject13 from "../../assets/logo-project13.png";

const Header = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.data);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-3" : "d-flex flex-row justify-content-center"}>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">Be part of the journey</div>
                    <div className="color--gray">Subscribe to be the first known about our latest merch drops and offers</div>
                    <div className="newsletter__input">
                        <input type="text" placeholder="Your email address goes here..." />
                        <div className="newsletter__input__button">Subscribe</div>
                    </div>
                    <br/>
                    <div className="color--black">Be safe online with us!</div>
                    <div className="d-flex flex-column justify-content-center gap-3">
                        <img className={isMobile ? "payments__icon m-auto" : "payments__icon"} src="https://imgur.com/7hiHm7M.png" alt="Safe pay methods." />
                        {/* <a href="https://www.librapay.ro" title="Plati online" target="_blank"><img src="https://www.librapay.ro/images/icon_securitate_LibraPay_180x192px.jpg" alt="Icon securitate LibraPay" border="0" /></a>  */}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">Useful Links</div>
                    <Link to="/contact" className="color--gray">Contact Us</Link>
                    <Link to="/about">About us</Link>
                    <Link to="/size-guide" className="color--gray">Size Guide</Link>
                    <Link to="/custom-merch">Custom Merch</Link>
                    <Link to="/design-gallery">Design Gallery</Link>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">Policies</div>
                    <Link to="/privacy" className="color--gray">Privacy Policy</Link>
                    <Link to="/tos" className="color--gray">Terms of Service</Link>
                    <Link to="/return" className="color--gray">Return and Refund Policy</Link>
                    <Link to="/shipping" className="color--gray">Shipping Policy</Link>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">Categories</div>
                    {
                        categories.filter(value => value.type == "primary").map((category, index) => (
                            <Link key={index} to={`/products`} className="choice"  onClick={() => dispatch(setFilterCategories([category]))}>{category.title}</Link>
                        ))
                    }
                </div>
            </div>

            <div class="disclaimer">
                <a href="https://ctrl13.ro">
                    <img src={LogoProject13} alt="project13" class="logo" />
                </a>

                <a href="https://project13.ro">project13.ro</a>
            </div>

            <div className="copyright">
                <div className="text-center">
                    Copyright &copy; 2023 SUFYi - All rights reserved.
                </div>

                <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-3" : "d-flex flex-row justify-content-center gap-4"}>
                    <a href="https://anpc.ro/">ANPC - Autoritatea Nationala pentru Protectia Consumatoriilor.</a>
                    <a href="https://anpc.ro/ce-este-sal/">ANPC - Solutionarea Alternativa a Litigiilor.</a>
                </div>
            </div>
        </footer>
    );
}

export default Header;