import React, { useEffect, useState } from 'react';
import { Layout } from "../common/utils";
import { Helmet } from 'react-helmet';

import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SHA256 } from 'crypto-js';

import { PageHeader } from '../common';
import { changeData } from '../../redux/actions/account';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);

    const [email, setEmail] = useState(null);
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [phone, setPhone] = useState(null);

    const [password, setPassword] = useState(null);
    const [cPassword, setConfirmedPassword] = useState(null);

    return (
        <>
            <Helmet>
                <title>Your account.</title>
            </Helmet>
            <PageHeader page="Your account" />

            <Layout>
                {
                    localStorage.getItem('visitor') === "true"?
                    <Redirect to="/login" />
                    :
                    <div className={isMobile ? 'd-flex flex-column p-5 m-auto' : 'd-flex flex-column m-auto'}>
                        <br />
                        <h2 className='text-start text-black'>
                            <b>Hello, {account?.firstname}!</b>
                        </h2>
                        <div className={isMobile ? "d-flex flex-column justify-content-start gap-2" : "d-flex flex-row justify-content-start gap-2"}>
                            <Link className='button' to='/orders'>Check your orders</Link>
                            {
                                account?.type === "master" &&
                                <>
                                    <Link className='button' to="/promocodes">Manage promocodes</Link>
                                    <Link className='button' to='/insert'>Add products</Link>
                                </> 
                            }
                        </div>
                        <h2 className='text-start text-black mt-5'>
                            <b>Your account's information:</b>
                        </h2>
                        <ul className='d-flex flex-column justify-content-start list-unstyled'>
                            <li><b>EMAIL</b><br />{account?.email}</li>
                            <li><b>FIRST NAME</b><br />{account?.firstname}</li>
                            <li><b>LAST NAME</b><br />{account?.lastname}</li>
                            <li><b>PHONE NUMBER</b><br />{account?.phone}</li>
                        </ul>
                        <br />
                        <h2 className='text-start text-black mt-5'>
                            <b>Update your account's information:</b>
                        </h2>
                        <center>
                            <div className={isMobile ? 'input__group d-flex flex-column justify-content-around w-100': 'input__group d-flex flex-row justify-content-around w-100'}>
                                <input type="text" placeholder="Prenume" value={firstname} onChange={e => setFirstname(e.target.value)} />
                                <input type="text" placeholder="Nume" value={lastname} onChange={e => setLastname(e.target.value)} />
                                <input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                <input type="text" placeholder="Numar telefon" value={phone} onChange={e => setPhone(e.target.value)} />
                            </div>
                        </center>
                        <div className={isMobile ? "button m-auto" : "button w-25 mx-4"} onClick={() => {
                            if (firstname !== null && lastname !== null && email !== null && phone !== null) {
                                dispatch(changeData({
                                    firstname: firstname,
                                    lastname: lastname,
                                    email: email,
                                    phone: phone
                                }))
                            }
                            else {
                                alert("Toate campurile sunt obligatorii!");
                            }
                        }}>Apply</div>
                        <br />
                        <h2 className='text-start text-black mt-5'>
                            <b>Change your password:</b>
                        </h2>
                        <center>
                            <div className={isMobile ? 'input__group d-flex flex-column justify-content-around w-100': 'input__group d-flex flex-row justify-content-around w-100'}>
                                <input type="password" placeholder="New password" onChange={text => setPassword(text.target.value)}/>
                                <input type="password" placeholder="Confirm new password" onChange={text => setConfirmedPassword(text.target.value)}/>
                            </div>
                        </center>
                        <div className={isMobile ? "button m-auto" : "button w-25 mx-4"} onClick={() => {
                            if (password === cPassword) {
                                dispatch(changeData({
                                    password: String(SHA256(password))
                                }))
                            }
                            else {
                                alert("The passwords do not correspond!");
                            }
                        }
                        }>Apply</div>
                        <br />
                        <div className={isMobile ? "button m-auto" : "button w-25 mx-4"} onClick={() => {
                            localStorage.removeItem("token");
                            window.location.reload();
                        }
                        }>Log out</div>
                    </div>
                }
            </Layout>
        </>
    );
};

export default Index;