import React, { useEffect, useState } from 'react';
import { Layout } from "../common/utils";
import { Helmet } from 'react-helmet';

import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeader } from '../common';
import { fetchPromos, addPromo, deletePromo } from '../../redux/actions/promo';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const promos = useSelector(state => state.promo.data);

    const [promo, setPromo] = useState({
        name: '',
        value: ''
    });

    useEffect(() => {
        dispatch(fetchPromos());
    }, []);

    return (
        <>
            <Helmet>
                <title>Promocode Manager.</title>
            </Helmet>
            <PageHeader page="Promocode Manager" />

            <Layout>
                {
                    account && account?.type == "master" ?
                    <div className={isMobile ? 'd-flex flex-column p-5 m-auto' : 'd-flex flex-column m-auto'}>
                        <h2 className='text-center'>
                            <b>Promocode Manager.</b>
                        </h2>
                        <hr />
                        <div className='d-flex flex-row justify-content-center flex-wrap gap-2'>
                        {
                            promos && promos.length > 0 && promos.map((promo, index) => (
                                <div className='border boder-1 border-dark p-3 pt-1 pb-1 rounded rounded-5 d-flex flex-row justify-content-between align-items-center gap-5'>
                                    <div>
                                        <b>Code:</b> {promo.name}<br />
                                        <b>Value:</b> {promo.value}%
                                    </div>
                                    <p className='text-center'>
                                        <div className="user-select-none">
                                            <span className="material-icons-outlined pointer" onClick={() => dispatch(deletePromo(promo))}>close</span>
                                        </div>
                                    </p>
                                </div>                                        
                            ))
                        }
                        </div>
                        <hr />
                        
                        <div className="input__group d-flex flex-column gap-2">
                            <div>
                                <label>Code:</label>
                                <input type="text" placeholder="..." value={promo?.name} onChange={e => setPromo({...promo, name: e.target.value})} />
                            </div>
                            <div>
                                <label>Value ( percentage ):</label>
                                <input type="text" placeholder="..." value={promo?.value} onChange={e => setPromo({...promo, value: e.target.value})} />
                            </div>

                            <div className='button' onClick={() => {
                                if (promo.name == '' || promo.value == '') return alert('Fill up all the fields!');
                                
                                dispatch(addPromo(promo))
                            }}>
                                Add
                            </div>
                        </div>
                    </div>
                    :
                    <Redirect to="/" />
                }
            </Layout>
        </>
    );
};

export default Index;