import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";

import ImageUploader from "./ImageUploader";
import { editPage } from "../../redux/actions/page";

const Slideshow = ({ image, landing }) => {
    return (
        <div className="landing__banner">
            <img className="background" src={image} alt='index' />
            <div className="content">
                {landing ?
                    <>
                        <div className="title">SUFYI Embroidery.</div>
                        <Link to="/products" className="button">Shop Now</Link>
                    </>
                    :
                    <>
                        <div className="title">Customize your outfit</div>
                        <Link to="/custom-merch" className="button">Customize Now</Link>
                    </>
                }
            </div>
        </div>
    );
}

export default Slideshow;